import { FaAngleDown } from 'react-icons/fa';
import { MdOpenInNew } from 'react-icons/md';
import { Trans, useTranslation } from 'react-i18next';
import { isFunction } from 'lodash';
import { ColorInput } from '@mantine/core';
import {TextInput, Textarea, Input as MantineInput} from '@mantine/core';
import { v4 as uuidv4 } from 'uuid';
import { DatePicker } from '@mantine/dates';
import React, { memo, useEffect, useState } from 'react';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from '../../contexts/ResumeContext';
import { handleKeyUp } from '../../utils';
import * as styles from './Input.module.css';

const Input = ({
  name,
  path,
  label,
  error,
  value,
  onBlur,
  options,
  touched,
  onClick,
  onChange,
  className,
  isRequired,
  placeholder,
  type = 'text',
  picker = true,
  swatches = [],
}) => {
  const { t } = useTranslation();
  const [uuid, setUuid] = useState(null);
  const stateValue = useSelector(path, '');
  const dispatch = useDispatch();

  useEffect(() => {
    setUuid(uuidv4());
  }, []);

  value = path ? stateValue : value;
  onChange = isFunction(onChange)
    ? onChange
    : (e) => {
      console.log('dispatch -> ', e);
        dispatch({
          type: 'on_input',
          payload: {
            path,
            value: e.target.value,
          },
        });
      };

  return (
    <div className={className}>
      <label htmlFor={uuid}>
        <span>
          {label}{' '}
          {isRequired && (
            <span className="opacity-75 font-normal lowercase">
              ({t('shared.forms.required')})
            </span>
          )}
        </span>

        {(type === 'text') && (
          <div className="relative grid items-center">
            <TextInput
              size="xs"
              id={uuid}
              name={name}
              type={type}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={placeholder}
            />
          </div>
        )}

        {(type === 'date') && (
          <div className="relative grid items-center">
            
            <MantineInput
              size="xs"
              id={uuid}
              name={name}
              type={type}
              value={value}
              onBlur={onChange}
              onChange={onChange}
              placeholder={placeholder}
            />
          {/*
            <DatePicker
              locale="en"
              type={type}
              id={uuid}
              path={path}
              onBlur={updater}
//              fixOnBlur={true}
//              inputFormat="YYYY-MM-DD"
//              labelFormat="YYYY-MM-DD"
//              onBlur={(e)=>{onChange({target:{value: dayjs(new Date(e.target.value)).clone().format("YYYY-MM-DD")}}); value = dayjs(e.target.value).clone().toDate(); console.log(e)}}
              name={name}
//              onBlur={(e)=>{onChange({target:{value: dayjs(e).format("YYYY-MM-DD")}}); console.log(dayjs(e).format("YYYY-MM-DD"));}}
              onChange={updater}
              value={new Date(value).toString() }
              placeholder={placeholder}
            />
        */}
        </div>
        )}

        {type === 'textarea' && (
          <div className="flex flex-col">
            <Textarea
              size="xs"
              id={uuid}
              minRows={8}
              autosize={true}
              maxRows={16}
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
              placeholder={placeholder}
            />

            <p className="mt-2 text-sm opacity-75">
              <Trans t={t} i18nKey="shared.forms.markdown">
                A
                <a
                  href="https://www.markdownguide.org/basic-syntax/"
                  className="text-blue-600"
                  target="blank"
                >
                  B
                </a>
                C
              </Trans>
            </p>
          </div>
        )}

        {type === 'dropdown' && (
          <div className="relative grid items-center">
            <select
              id={uuid}
              name={name}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            >
              {options.map((x) => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </select>

            <FaAngleDown
              size="16px"
              className="absolute right-0 opacity-50 hover:opacity-75 mx-4"
            />
          </div>
        )}

        {type === 'color' && (
          <div className="relative grid items-center">
            <div className={styles.circle} style={{ backgroundColor: value }} />

            
            <ColorInput
              id={uuid}
              name={name}
              value={value}
              onChange={(e)=>{onChange({target:{value:e}})}}
              placeholder={placeholder}
              withPicker={picker}
              swatches={swatches}
            />
          </div>
        )}

        {type === 'action' && (
          <div className={cx('relative grid items-center', styles.readOnly)}>
            <input readOnly id={uuid} name={name} type="text" value={value} />

            <div
              tabIndex="0"
              role="button"
              onClick={onClick}
              onKeyUp={(e) => handleKeyUp(e, onClick)}
            >
              <MdOpenInNew size="16px" />
            </div>
          </div>
        )}

        {error && touched && <p>{error}</p>}
      </label>
    </div>
  );
};

export default memo(Input);
